import React, { Component } from 'react'
import { Box, Button, Card, Icons, Input, List, LottoInfoBox, Modal, Page, Spacer } from 'components'
import { CQLotInfo } from 'api/types'
import { __, T } from 'translations/i18n'
import styled from '@emotion/styled'
import { showToast } from 'shared/utils'
import EncodingsCQ from 'api/EncodingsCQ'
import RemoteConfig from 'shared/RemoteConfig'
import { PageParams } from 'pages'
import { getLocationState } from 'shared/router'
import { toNumber } from 'lodash'

interface State {
  isLoading: boolean
  lots?: CQLotInfo[]
  lottoDetail?: CQLotInfo
  showModal: boolean
  lotData?: CQLotInfo
  totalQuantity?: number
  shipableQuantity?: number
  seriesCode?: string
  requestedQuantity?: number
}

interface Params extends PageParams {
  lotCode?: string
}

export default class QualityControlInfoLotto extends Component<{}, State> {
  state: State = {
    isLoading: false,
    lots: [],
    showModal: false,
    totalQuantity: 0,
    shipableQuantity: 0,
    requestedQuantity: 0,
  }
  inputRef = React.createRef<any>()
  clearInputRef: any = undefined
  // operation = RemoteConfig.getOperationConfig<any>('quality-control')

  params: Params = {
    ...getLocationState(this.props),
  }

  async componentDidMount() {
    if (this.params.lotCode) {
      this.searchSerie(this.params.lotCode)
    }
  }

  searchSerie = async (code: string) => {
    if (!code) return
    try {
      this.setState({ isLoading: true })
      if (code.length === 14 && code.charAt(10) === '-') {
        // Inserito un codice serie + lotto
        code = code.slice(0, 10)
      }
      console.log('code: ', code)
      // Inserito solo un codice serie
      const newLot = await EncodingsCQ.getCQInfo(code)
      if (!newLot || newLot.seriesLotQuantities.length === 0) throw Error(`Nessuna serie trovata`)
      this.clear()
      this.setState({
        requestedQuantity: newLot.requestedQuantity,
        totalQuantity: 0,
        shipableQuantity: 0,
      })
      newLot.seriesLotQuantities = newLot.seriesLotQuantities.sort((a, b) => (a.code > b.code ? 1 : -1))
      newLot.seriesLotQuantities.forEach((lot) => {
        if (lot.shippable)
          this.setState({ shipableQuantity: this.state.shipableQuantity! + toNumber(lot.testedTotalCount) })
        this.setState({
          totalQuantity: this.state.totalQuantity! + toNumber(lot.testedTotalCount),
        })
      })
      this.setState({
        isLoading: false,
        lots: newLot.seriesLotQuantities,
        seriesCode: newLot.seriesLotQuantities[0].seriesCode,
      })
      this.clearInputRef && this.clearInputRef()
    } catch (error) {
      this.setState({ isLoading: false })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  renderItem = (lotto: CQLotInfo) => {
    let status = lotto.status
    if (status === 'INSPECTED_1') status = 'In carico al lavorante'
    if (status === 'READY_INSPECTION_2') status = 'Pronta per il collaudo'
    if (status === 'INSPECTED_2') status = 'In carico al collaudatore'
    if (status === 'SUSPENDED') status = 'Sospesa'
    if (status === 'CLOSED') status = 'Chiuso'
    return (
      <SerieRow bgGrey row height={100}>
        <Box flex m={10} center>
          <Label>{lotto.code}</Label>
        </Box>
        <Box flex m={10} center>
          <Label style={{ textAlign: 'center' }}>{status}</Label>
        </Box>
        <Box flex m={10} center>
          <Label>{lotto.testedTotalCount}</Label>
        </Box>
        <Box flex m={10} center>
          {lotto.shippable && <Icons.CheckGreen style={{ alignSelf: 'center' }} width={48} height={48} />}
          {!lotto.shippable && <Icons.CheckRed style={{ alignSelf: 'center' }} width={48} height={48} />}
        </Box>
        <Box flex m={10} center>
          <Icons.Info
            style={{ alignSelf: 'center' }}
            ref={lotto.id}
            width={48}
            height={48}
            onClick={() => {
              this.setState({ lottoDetail: lotto, showModal: true })
            }}
          />
        </Box>
      </SerieRow>
    )
  }

  onCloseModal = () => this.setState({ showModal: false, lottoDetail: undefined })

  clear = () => this.setState({ lots: [], totalQuantity: 0, shipableQuantity: 0, seriesCode: '' })

  render() {
    const { isLoading, lots, showModal, lottoDetail, totalQuantity, shipableQuantity, seriesCode, requestedQuantity } =
      this.state
    return (
      <Page
        title="Info Serie/Lotto"
        header={{
          details: lots!.length > 0 ? [{ label: __(T.misc.serie), value: seriesCode }] : [],
        }}
      >
        <Page.Sidebar>
          <Input
            registerClear={(cb) => (this.clearInputRef = cb)}
            inputRef={this.inputRef}
            disabled={isLoading}
            autoFocus
            image={<Icons.Barcode />}
            placeholder="Barcode serie"
            onEnter={this.searchSerie}
          />
          {lots!.length > 0 && <ClearButton onClick={this.clear} variant="secondary" title={__(T.misc.clear)} />}
          <Spacer />
          {lots!.length > 0 && (
            <Card title={__(T.misc.serie_info_cq)}>
              <Label>
                {__(T.misc.total_quantity_serie)}: {requestedQuantity}
              </Label>
              <Label>
                {__(T.misc.total_quantity_lots)}: {totalQuantity}
              </Label>
              <Label>
                {__(T.misc.shipable_quantity)}: {shipableQuantity}
              </Label>
            </Card>
          )}
        </Page.Sidebar>
        <Page.Content>
          {lots!.length > 0 && (
            <SerieRow row>
              <Box flex m={10} center>
                <Label>LOTTO</Label>
              </Box>
              <Box flex m={10} center>
                <Label>STATO</Label>
              </Box>
              <Box flex m={10} center>
                <Label>QUANTITÀ</Label>
              </Box>
              <Box flex m={10} center>
                <Label>SPEDIBILE</Label>
              </Box>
              <Box flex m={10} center>
                <Label>DETTAGLIO</Label>
              </Box>
            </SerieRow>
          )}
          <List data={lots!} loading={isLoading} renderItem={this.renderItem} />
        </Page.Content>
        <Modal visible={showModal} onClose={this.onCloseModal} size="3xl" fullContent>
          <LottoInfoBox lot={lottoDetail!} />
        </Modal>
      </Page>
    )
  }
}

const ClearButton = styled(Button)`
  margin-top: 30px;
`

const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #333333;
`

const SerieRow = styled(Box)`
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  margin-bottom: 5px;
`
