import { ApisauceConfig } from 'apisauce'
import { EncodingConfig } from 'shared/RemoteConfig'
import api, { responseErrorCheck } from './api'
import { CustomEncodingProductionOrderRowDto, CustomTmrItem, SeriesLotInfo, TestingSheet } from './types'

export type ValidationBodyRequest = {
  productionOrderId?: string
  productId: string
  identifiers: {
    code: string
    type: string
  }[]
  zoneId?: string
  configurationId: string
  causalId?: string
  reversible?: boolean
  testingLotIds?: string[]
  scannedBarCode?: string
}

export default class EncodingsCQ {
  static associate(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/operations/encoding/create', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static associateLot(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/custom/seriesLots/operations/encoding/create', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static drop(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(`/custom/operations/encoding/reject?reversible=${params.reversible}`, params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static dropLotWorker(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(
        `/custom/seriesLots/operations/encoding/worker/reject?reversible=${params.reversible}`,
        params,
        requestConfig
      )
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static dropLotInspector(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>(
        `/custom/seriesLots/operations/encoding/inspector/reject?reversible=${params.reversible}`,
        params,
        requestConfig
      )
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static delete(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/custom/operations/encoding/reset', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static deleteLot(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/custom/seriesLots/operations/encoding/reset', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static deleteLotWorker(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/custom/seriesLots/operations/encoding/worker/reset', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static deleteLotInspector(params: ValidationBodyRequest, requestConfig?: ApisauceConfig) {
    return api
      .post<any>('/custom/seriesLots/operations/encoding/inspector/reset', params, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static verify(
    body: {
      identifiers: {
        code: string
        type: string
      }[]
      configurationId: string
      productionOrderId: string
      productId: string
    },
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>('/operations/encoding/verify', body, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static verifyCQWorker(
    body: {
      identifiers: {
        code: string
        type: string
      }[]
      configurationId: string
      productionOrderId: string
      productId: string
      operationType?: string
    },
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>('custom/seriesLots/operations/encoding/worker/verify', body, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static verifyCQInspector(
    body: {
      identifiers: {
        code: string
        type: string
      }[]
      configurationId: string
      productionOrderId: string
      productId: string
      operationType?: string
      testingLotIds?: string[]
    },
    requestConfig?: ApisauceConfig
  ) {
    return api
      .post<any>('custom/seriesLots/operations/encoding/inspector/verify', body, requestConfig)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getCustomProductionOrder(code: string, encodingConfig: EncodingConfig, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.get<CustomEncodingProductionOrderRowDto>(
        `/operations/encoding/info/${encodingConfig.id}/order/${code}`,
        requestConfig
      )
    )
    return data
  }

  static async getCQCustomProductionOrder(
    code: string,
    encodingConfig: EncodingConfig,
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.get<CustomEncodingProductionOrderRowDto>(
        `/custom/seriesLots/operations/encoding/info/${encodingConfig.id}/order/${code}`,
        requestConfig
      )
    )
    return data
  }

  static async getCQInfoProductionOrder(code: string, configurationId: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.get<any>(`custom/seriesLots/${code}/quantities`, requestConfig))
    return data
  }

  static async getCQInfo(code: string, configurationId?: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.get<SeriesLotInfo>(`custom/seriesLots/${code}/lots`, requestConfig))
    return data
  }

  static async getCQLotPdfInfo(code: string, configurationId: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.get<any>(`custom/seriesLots/${code}/quantities/pdf`, requestConfig, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
    )
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${code}.pdf`)
    document.body.appendChild(link)
    link.click()
  }

  static async getSerieUpdate(seriesId: string, configurationId: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.get<CustomEncodingProductionOrderRowDto>(
        `custom/operations/encoding/series/${seriesId}/count?configuration=${configurationId}`,
        requestConfig
      )
    )
    return data
  }

  static async fetchCausal(type: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.get<any>(`/causals`, { type }, requestConfig))
    return data
  }

  static async fetchItemById(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.get<any>(`/custom/items/${id}`, requestConfig))
    return data
  }

  static async fetchItemByIdentifier(
    identifier: string,
    securityFilterEnabled = false,
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.get<any>(`/custom/items`, { 'identifier.code': identifier, securityFilterEnabled }, requestConfig)
    )
    return data
  }

  static async forceCollaudo(item: CustomTmrItem, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.post<any>(`/custom/items/${item.id}`, item, requestConfig))
    return data
  }

  static async changeStatusSerie(id: string, status: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/operations/encoding/series/${id}?status=${status}`, requestConfig)
    )
    return data
  }

  static async closeSerie(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/operations/encoding/series/${id}/publish`, requestConfig)
    )
    return data
  }

  static async getEncodable(seriesCode: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.get<any>(`custom/productionOrders/${seriesCode}/cq/quantities`, requestConfig)
    )
    return data
  }

  static async getEncodableCalzature(seriesCode: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.get<Map<string, number>>(`custom/productionOrders/${seriesCode}/cq/quantities/calzature`, requestConfig)
    )
    return data
  }

  static async interruptSerie(id: string, configurationId: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(
        `/custom/operations/encoding/series/${id}/stop?configuration=${configurationId}`,
        requestConfig
      )
    )
    return data
  }

  static async changeStatusSerieToInspected(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/operations/encoding/series/${id}/testing`, requestConfig)
    )
    return data
  }

  static async changeStatusSerieToSuspended(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/operations/encoding/series/${id}/suspend`, requestConfig)
    )
    return data
  }

  static async changeStatusSerieToReadyInspection(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/operations/encoding/series/${id}/totest`, requestConfig)
    )
    return data
  }

  static async changeStatusLottoToReadyInspection(id: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/seriesLots/operations/encoding/${id}/toTest`, requestConfig)
    )
    return data
  }

  static async changeStatusLottiToInspected(
    body: {
      orderId: string
      lotIds: string[]
    },
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/seriesLots/operations/encoding/testing`, body, requestConfig)
    )
    return data
  }

  static async publishLotti(
    body: {
      orderId: string
      lotIds: string[]
    },
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/seriesLots/operations/encoding/publish`, body, requestConfig)
    )
    return data
  }

  static async interruptLotti(
    body: {
      orderId: string
      lotIds: string[]
    },
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.put<any>(`/custom/seriesLots/operations/encoding/stop`, body, requestConfig)
    )
    return data
  }

  static async getQualitySheet(
    qualityCode: string,
    workerCode: string,
    productStyle: string,
    requestConfig?: ApisauceConfig
  ) {
    const data = responseErrorCheck(
      await api.get<any>(
        `/qualitySheets?qualityCodes=${qualityCode}&order.worker.code=${workerCode}&productStyle=${productStyle}`,
        requestConfig
      )
    )
    return data
  }

  static async getTestingSheet(serieId: string, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.get<any>(`/inspectionSheets?order.id=${serieId}`, requestConfig))
    return data
  }

  static async createTestingSheet(testingSheet: Partial<TestingSheet>, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(await api.post<any>(`/inspectionSheets/create`, testingSheet, requestConfig))
    return data
  }

  static async updateTestingSheet(testingSheet: Partial<TestingSheet>, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.post<any>(`/inspectionSheets/${testingSheet.id}`, testingSheet, requestConfig)
    )
    return data
  }
  static async addFilesQualitySheet(testingSheetCode: string, files: any, requestConfig?: ApisauceConfig) {
    const data = responseErrorCheck(
      await api.post<any>(`/inspectionSheets/${testingSheetCode}/files`, files, requestConfig)
    )
    return data
  }

  static async confirmWorkerLottiNoTag(lotCode: string, productId: string, quantity: number) {
    const params = {
      lotCode,
      productId,
      quantity,
    }
    return api
      .post<any>('custom/seriesLots/worker/confirm', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async confirmInspectorLottiNoTag(lotCode: string, productId: string, causalsQuantityMap: Map<string, number>, quantity: number) {
    const params = {
      lotCode,
      productId,
      causalsQuantityMap: Object.assign({}, ...Array.from(causalsQuantityMap.entries()).map(([k, v]) =>({[k]: v}) )),
      quantity,
    }
    return api
      .post<any>('custom/seriesLots/inspector/confirm', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async getSerieInfoInspector(lotCode: string, encodingConfig: EncodingConfig, requestConfig?: ApisauceConfig) {
    return responseErrorCheck(
      await api.get<CustomEncodingProductionOrderRowDto>(
        `/custom/seriesLots/operations/encoding/infoInspector/${encodingConfig.id}/order/${lotCode}`,
        requestConfig
      )
    )
  }

  static async confirmInspectorCentoNoTag(lotCode: string, productId: string, causalsQuantityMap: Map<string, number>, quantity: number) {
    const params = {
      lotCode,
      productId,
      causalsQuantityMap: Object.assign({}, ...Array.from(causalsQuantityMap.entries()).map(([k, v]) =>({[k]: v}) )),
      quantity,
    }
    return api
      .post<any>('custom/seriesLots/inspector/confirm/notag', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async inspectorCentoItemRead(lotCode: string, epc: string, causal?: string) {
    const params = {
      lotCode,
      epc,
      causal,
    }
    return api
      .post<any>('custom/items/inspector/tag/read', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async confirmWorkerLottiNoTagCalzature(lotCode: string, quantity: Map<string, number>) {
    const params = {
      lotCode,
      quantity: Object.assign({}, ...Array.from(quantity.entries()).map(([k, v]) =>({[k]: v}) )),
    }
    return api
      .post<any>('custom/seriesLots/worker/calzature/confirm', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }

  static async confirmInspectorLottiNoTagCalzature(lotCode: string, causalsQuantityMap: Map<string, Map<string, number>>, quantity: Map<string, number>) {
    const params = {
      lotCode,
      causalsQuantityMap:  Object.assign({}, ...Array.from(causalsQuantityMap.entries()).map(([k1, v1])=>({[k1]: Object.assign({}, ...Array.from(v1.entries()).map(([k2, v2]) =>({[k2]: v2}) ))}) )),
      quantity: Object.assign({}, ...Array.from(quantity.entries()).map(([k, v]) =>({[k]: v}) )),
    }
    return api
      .post<any>('custom/seriesLots/inspector/calzature/confirm', params)
      .then(responseErrorCheck)
      .then((res) => res)
  }
}
