import React, { Component } from 'react'
import {
  AntennaBottomControl,
  Box,
  Button,
  Card, CustomSpacer,
  Icons,
  InfoCard,
  InfoModal,
  Input,
  KeyValueRow,
  Page,
  Placeholder,
  Select,
  SelectItemModal,
  Spacer,
  Tab,
  TextBox,
} from 'components'
import AppStore from 'AppStore'
import { __, T } from 'translations/i18n'
import styled from '@emotion/styled'
import { askUserConfirmation, closeModal, openModal, showToast, sleep } from 'shared/utils'
import { Causal, CQCounters, CQLot, CQSerie, CQTag, ProductionOrderRow, QualitySheet, TestingSheet } from 'api/types'
import RemoteConfig from 'shared/RemoteConfig'
import EncodingsCQ from 'api/EncodingsCQ'
import Sounds from 'shared/Sounds'
import SeriesNotesModal from 'components/modals/SeriesNotesModal'
import SelectLotsModal from 'components/modals/SelectLotsModal'
import DataDogLogger from 'shared/DataDogLogger'
import QualitySheetModal from 'components/modals/QualitySheetModal'
import QualityControlAgent from './QualityControlAgentNew'
import QualityControlCausal from '../../components/QualityControlCausal'
import QualityProvider from './QualityProvider'
import QualityControlQuantity from '../../components/QualityControlQuantity'
import _ from 'lodash'

interface State {
  showQualitySheetModal: boolean
  options: { value: string; label: string; active: boolean }[]
  isReading: boolean
  isVerifing: boolean
  isWriting: boolean
  errorSerie: any
  serie?: CQSerie
  serieCode: string
  counters?: CQCounters
  item?: any
  tag?: CQTag
  warningText?: string
  isSerieSet: boolean
  isActionInProgress: boolean
  causal: Causal[]
  selectedCausal?: Causal
  qualitySheetData?: QualitySheet
  lastSearchedSerieCode?: string
  showScartoModal: boolean
  hideStartReaderButton: boolean
  lots?: CQLot[]
  showSeriesNotesModal: boolean
  testingSheetData?: TestingSheet
  testedPieces: number
  inspectorTested?: number
  discarded?: number
  toBeTested?: number
  isVAS: boolean
  hasUnexpectedTag?: boolean
  lastReadedTag?: string
  causalsCount: number,
  quantityToAdvance: Map<string, number>,
  causalQuantityMap: Map<string, Map<string, number>>,
  originalQuantity: Map<string, number>,
  fastCode?: string,
  serieHasTag?: boolean,
  lotQuantity: number,
  rows: ProductionOrderRow[],
}

export default class QualityControlInspector extends Component<{}, State> {
  state: State = {
    showQualitySheetModal: false,
    options: [{ value: 'COLLAUDO', label: 'collaudo', active: true }],
    isReading: false,
    isVerifing: false,
    isWriting: false,
    causal: [],
    errorSerie: '',
    isSerieSet: false,
    isActionInProgress: false,
    showScartoModal: false,
    hideStartReaderButton: false,
    showSeriesNotesModal: false,
    isVAS: false,
    hasUnexpectedTag: false,
    lastReadedTag: undefined,
    testedPieces: 0,
    causalsCount: 0,
    causalQuantityMap: new Map<string, Map<string, number>>(),
    quantityToAdvance: new Map<string, number>(),
    originalQuantity: new Map<string, number>(),
    lotQuantity: 0,
    serieCode: '',
    rows: [],
  }
  tipoCQ = AppStore.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.tipocq']
  isWSM2 = AppStore.defaultPlace?.attributes?.['tmr.ferragamo.integration.fast.series.wsm2.worker'] === 'S'
  clearInputRef: any = undefined
  inputRef = React.createRef<HTMLInputElement>()
  operation = RemoteConfig.getOperationConfig<any>('quality-control-inspector')
  isPelletteria = (AppStore.defaultPlace?.attributes as any)?.categoryType === 'P'
  async componentDidMount() {
    try {
      await this.stopReader()
      const causal = await EncodingsCQ.fetchCausal(this.isPelletteria ? 'P' : 'C')
      this.setState({ causal })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'fetchCausal' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
    }
  }

  async componentWillUnmount() {
    await this.stopReader()
  }

  clearStatus = async () => {
    const { isSerieSet, serieHasTag } = this.state
    await this.stopReader()
    this.inputRef = this.clearInputRef
    this.setState({
      showQualitySheetModal: false,
      options: isSerieSet ? (serieHasTag
        ? [{ value: 'COLLAUDO', label: 'collaudo', active: true },
          { value: 'SCARTO', label: 'scarto', active: false },
          { value: 'CANCELLAZIONE', label: 'cancellazione', active: false }]
        : [{ value: 'COLLAUDO', label: 'collaudo', active: true },
          { value: 'SCARTO', label: 'scarto', active: false }]) : [],
      isReading: false,
      isVerifing: false,
      isWriting: false,
      errorSerie: '',
      isSerieSet: false,
      isActionInProgress: false,
      qualitySheetData: undefined,
      serie: undefined,
      counters: undefined,
      item: undefined,
      tag: undefined,
      warningText: undefined,
      showScartoModal: false,
      lots: undefined,
      testingSheetData: undefined,
      isVAS: false,
      hasUnexpectedTag: false,
      causalQuantityMap: new Map<string, Map<string, number>>(),
      causalsCount: 0,
      testedPieces: 0,
      serieCode: '',
    })
  }

  toggleQualitySheetDetail = () => {
    const { showQualitySheetModal, serie } = this.state
    DataDogLogger.addAction('toggleQualitySheetDetail', {
      serie: serie ?? 'serie is undefined',
      show: !showQualitySheetModal,
    })
    this.setState({ showQualitySheetModal: !showQualitySheetModal })
  }

  changeOptions = async (selectedOption: string) => {
    const { serieHasTag, options, lotQuantity, serieCode, causalQuantityMap, quantityToAdvance } = this.state
    options.map((option) => {
      option.active = option.value === selectedOption
      return option
    })
    if (!serieHasTag) {
      if (this.isPelletteria) {
        quantityToAdvance.set(serieCode, selectedOption === 'COLLAUDO' ? lotQuantity : quantityToAdvance.get(serieCode) ?? 0)
      }
      else {
        if (selectedOption === 'COLLAUDO') {
          Array.from(causalQuantityMap.entries()).forEach(([key1, value1]) => {
            let somma = 0
            Array.from(value1.entries()).forEach(([key2, value2]) => {
              somma += value1.get(key2) ?? 0
            })
            quantityToAdvance.set(key1, (quantityToAdvance.get(key1) ?? 0) + somma)
          })
        }
      }
      this.setState({
        options,
        causalQuantityMap: new Map<string, Map<string, number>>(),
        causalsCount: 0,
        quantityToAdvance,
      })
    }
  }

  getUpdatedCounters = async (code?: string) => {
    const { serie } = this.state
    const serieCode = code ?? serie!.code
    const counters: CQCounters = await EncodingsCQ.getEncodable(serieCode)
    if (!counters) throw Error('Impossibile definire contatori serie')
    return counters
  }

  //funzione chiamata quando si cerca una serie
  searchSerie = async (code: string) => {
    await this.getUpdatedSerie(code)
  }

  getUpdatedSerie = async (code: string, startReader = true) => {
    try {
      await this.stopReader()
      code = code.toUpperCase()
      const { serie, options } = this.state
      let warningText
      if (this.isPelletteria && code.length !== 10) throw new Error(__(T.error.serie_code_10_characters))
      if (!this.isPelletteria && code.length !== 14) throw new Error(__(T.error.serie_code_14_characters))

      let newSerie
      if (this.tipoCQ === 'CENTO') {
        newSerie = await EncodingsCQ.getSerieInfoInspector(code, this.operation)
      }
      else {
        newSerie = await EncodingsCQ.getCQCustomProductionOrder(code, this.operation)
      }

      if (!newSerie) throw new Error(__(T.error.serie_not_found))
      if (newSerie.worker.code !== AppStore.defaultPlace?.code) throw new Error(__(T.error.serie_wrong_place))

      if (serie && newSerie?.code !== serie?.code) {
        if (
          !(await askUserConfirmation(
            __(T.messages.serie_change_confirm),
            __(T.messages.serie_close_product_not_associated)
          ))
        )
          return false
        await this.closeLotto()
      }

      let row
      newSerie.rows.sort((a, b) => a.product.size.value - b.product.size.value)
      if (this.isPelletteria) row = newSerie.rows[0]
      else row = newSerie.rows.find((r) => r.rowCode === code) as any

      if (!row?.product) throw Error('Taglia/calzata non trovata')
      DataDogLogger.addAction('searchSerie', { serie: newSerie })
      if (newSerie.availableQuantity === 0)
        showToast({
          title: 'Attenzione',
          description: "Esistono pezzi battezzati sulla serie. Usa l'operazione di cancellazione per rimuoverli",
          status: 'warning',
        })
      let hideStartReaderButton = false
      let hasUnexpectedTag = false
      let fastCode = ''
      let serieHasTag = true
      if (newSerie && newSerie.destination && newSerie.destination.activeTag !== '') {
        warningText = newSerie.destination.fastMess
        fastCode = newSerie.destination.fastCode
        if (newSerie.destination!.activeTag === 'N') hideStartReaderButton = true
        if (warningText === 'NON DEVE CONTENERE IL TAG') hasUnexpectedTag = true
        serieHasTag = await QualityProvider.serieHasTag(fastCode)
        this.setState({ fastCode })
      }
      let isVAS = false
      if (newSerie && newSerie.customerOrder && newSerie.customerOrder === '24S1') isVAS = true

      const qualitySheetData = (
        await EncodingsCQ.getQualitySheet(
          newSerie.qualityCode || '',
          newSerie?.worker.code,
          row?.product?.style?.value ?? ''
        )
      )[0] // 'Q_CODE_TEST', 'studio-pelle'
      const testingSheetData = (await EncodingsCQ.getTestingSheet(newSerie.id))[0]
      const counters = await this.getUpdatedCounters(newSerie.code)
      if (!counters) throw new Error(__('Errore nel recupero dei counters'))
      const lotCounts = counters!.itemStatusCounts.lotCounts
      let filteredLots: CQLot[]
      if (serie && newSerie?.code === serie?.code) {
        filteredLots = newSerie.lots!.filter(
          (lot) => serie.data.currentLotData!.map((currentLot) => currentLot.id).indexOf(lot.id) !== -1
        )
      } else {
        filteredLots = newSerie.lots!.filter(
          (lot) => lot.status === 'INSPECTED_2' || lot.status === 'READY_INSPECTION_2'
        )
        if (filteredLots.length === 0) throw new Error(__('Nessun lotto da ispezionare'))
        if (filteredLots.length > 1) filteredLots = await this.showSelectLotsModal(filteredLots, lotCounts)
        await EncodingsCQ.changeStatusLottiToInspected({
          orderId: newSerie!.code,
          lotIds: filteredLots!.map(({ id }) => id),
        })
      }
      const filteredLotsToInterrupt = filteredLots!.filter((lot) => lot.items.length === 0)
      if (filteredLotsToInterrupt.length > 0 && this.tipoCQ === 'CAMP_LOTTI') {
        await this.showInterruptModal(filteredLotsToInterrupt, newSerie.id)
        filteredLots = filteredLots!.filter((lot) => lot.items.length !== 0)
        if (filteredLots.length === 0) {
          throw new Error(__('Nessun lotto da ispezionare'))
        }
      }
      const selectedLotsCounters = lotCounts.filter(
        ({ lotId }) => filteredLots!.map(({ id }) => id).indexOf(lotId) !== -1
      )
      let testedPieces = 0
      let inspectorTested = 0
      let discarded = 0
      let toBeTested = 0
      let total = 0
      for (let i = 0; i < selectedLotsCounters.length; i++) {
        testedPieces += selectedLotsCounters[i].tested!
        inspectorTested += selectedLotsCounters[i].inspectorTested
        discarded += selectedLotsCounters[i].discardedNR!
        toBeTested += selectedLotsCounters[i].toBeTested!
        total += selectedLotsCounters[i].total!
      }
      const newSerieFormatted: CQSerie = {
        id: newSerie.id,
        code: newSerie.code,
        productId: row.product.id,
        data: {
          values: {
            'Tag/Cal': `${row.product.size.value}/${row.product.fit.value}`,
            Modello: row.product.style.value,
            Variante: row.product.variant.value,
            Stagione: row.product.season.slice(0, 2) + row.product.season.slice(4),
            Lavorante: newSerie.worker.description ?? 'aa',
            'Pezzi totali serie': counters.itemStatusCounts.seriesSize,
          },
          additional: {
            workerSyncCode: newSerie.worker.syncCode,
            productSku: row.product.sku,
            productSize: row.product.size.value,
            productCode: row.product.code ?? '',
            customerOrder: newSerie.customerOrder?.trim() ?? '',
            nationCode: newSerie.worker.countryCode ?? '',
          },
          descriptions: {
            Modello: row.product.style.valueDescription,
            Taglia: row.product.size.valueDescription,
            Calzata: row.product.fit.valueDescription,
            Variante: row.product.variant.valueDescription,
          },
          currentLotData: filteredLots,
        },
      }
      let encodable = new Map<string, number>()
      if (this.isPelletteria) {
        encodable.set(newSerie.code, newSerieFormatted.data.values['Pezzi totali serie'] - QualityProvider.getEncodati(counters))
      }
      else {
        encodable = await EncodingsCQ.getEncodableCalzature(newSerie.code)
      }
      const quantityToAdvance = this.getQuantityToAdvance(newSerie.code, testedPieces, encodable, toBeTested) ?? new Map<string, number>()
      const lots = newSerie.lots!.filter((lot) => lot.status === 'INSPECTED_2' || lot.status === 'READY_INSPECTION_2')
      this.setState({
        serie: newSerieFormatted,
        errorSerie: undefined,
        warningText,
        isSerieSet: true,
        counters,
        qualitySheetData,
        lastSearchedSerieCode: code,
        hideStartReaderButton,
        lots,
        testingSheetData,
        testedPieces,
        inspectorTested,
        discarded,
        toBeTested,
        isVAS,
        hasUnexpectedTag,
        quantityToAdvance,
        serieHasTag,
        originalQuantity: _.cloneDeep(quantityToAdvance),
        lotQuantity: this.isPelletteria ? encodable.get(newSerie.code) ?? 0 : this.setLotQuantity(encodable),
        options: serieHasTag
          ? [{ value: 'COLLAUDO', label: 'collaudo', active: true },
            { value: 'SCARTO', label: 'scarto', active: false },
            { value: 'CANCELLAZIONE', label: 'cancellazione', active: false }]
          : [{ value: 'COLLAUDO', label: 'collaudo', active: true },
            { value: 'SCARTO', label: 'scarto', active: false }],
        rows: newSerie.rows,
        serieCode: newSerie.code,
      })
      Sounds.tap()
      const mode = options?.find((opt) => opt?.active === true)?.value
      //in scarto non faccio partire il reader automaticamente perchè va prima selezionata la causale di scarto
      if (mode === 'COLLAUDO' && newSerie && startReader && serieHasTag) {
        this.startReader()
      }
      return true
    } catch (error) {
      DataDogLogger.addError(error, { type: 'searchSerie' })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? __(T.error.serie_error),
        status: 'error',
      })
      this.clearStatus()
      return false
    }
  }

  setLotQuantity = (encodable: Map<string, number>) => {
    return Object.values(encodable).reduce((somma, value) => somma + value, 0)
  }

  getQuantityToAdvance = (serieCode: string, testedPieces: number, encodable: Map<string, number>, toBeTested?: number) => {
    const { serieHasTag } = this.state
    const quantityToAdvance = new Map<string, number>()
    if (this.isPelletteria) {
      if (this.tipoCQ === 'CAMP_LOTTI') { // CQ lotti, no tag, wsm2
        quantityToAdvance.set(serieCode, testedPieces)
      }
      else if (this.tipoCQ === 'CENTO' && !serieHasTag && !this.isWSM2) { // CQ cento, no tag, no wsm2
        quantityToAdvance.set(serieCode, encodable.get(serieCode) ?? 0)
      }
      else if (this.tipoCQ === 'CENTO' && !serieHasTag && this.isWSM2) { // CQ cento, no tag, wsm2
        quantityToAdvance.set(serieCode, toBeTested ?? 0)
      }
    }
    else {
      const map = new Map(Object.entries(encodable));
      map.forEach((value, key) => {
        if (value > 0){
          quantityToAdvance.set(key, value)
        }
      })
    }
    return quantityToAdvance
  }

  addCausaleCalz = (rowCode: string) => {
    try {
      const { rows, causalQuantityMap } = this.state
      if (!rows) throw new Error('Nessun lotto selezionato')
      const riga = rows.filter((row) => row.rowCode === rowCode)
      if (riga.length === 0) throw new Error('Nessuna riga trovata')
      if (!causalQuantityMap.has(rowCode)) {
        causalQuantityMap.set(rowCode, new Map<string, number>())
      }
      causalQuantityMap.get(rowCode)!.set('', 0)
      this.setState({ causalQuantityMap })
    }
    catch (error) {
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  showInterruptModal = async (rows: CQLot[], code: string) => {
    const modalId = 'cq-lots-interrupt'
    const rowCodes = rows.map((row) => row.code).join(', ')
    const message =
      rows.length > 1
        ? `I lotti ${rowCodes} non contengono pezzi collaudabili e verranno interrotti`
        : `Il lotto ${rowCodes} non contiene pezzi collaudabili e verrà interrotto`
    return new Promise<any>((resolve) => {
      openModal({
        id: modalId,
        body: (
          <InfoModal
            title="conferma interruzione lotto"
            subtitle={message}
            onClose={() => {
              this.interruptLotto(rows, code)
              resolve(0)
              closeModal(modalId)
              return 0
            }}
          />
        ),
      })
    })
  }

  //Mostra modale per selezione lotti
  showSelectLotsModal = async (rows: any[], lotCounts: any[]) => {
    const modalId = 'cq-lots'
    const selectedLots: any[] = []
    rows = rows.sort((a, b) => (a.code > b.code ? 1 : -1))
    return new Promise<any>((resolve, reject) => {
      openModal({
        id: modalId,
        body: (
          <SelectItemModal
            title="Seleziona lotto/i"
            visible
            multiple
            forceSelect
            customBtnText="Conferma"
            selected={selectedLots}
            options={rows ?? []}
            onSelect={(items) => {
              resolve(items)
              closeModal(modalId)
            }}
            field="code"
            label="status"
            onClose={() => {
              return 0
            }}
            customRowRender={(row: CQLot, selected: boolean) => {
              const date = new Date(row.creationDate).toLocaleDateString()
              const time = new Date(row.creationDate).toLocaleTimeString()
              const dateTime = `${date} ${time}`
              const counters = lotCounts.filter((c) => c.lotId === row.id)[0]
              const pieces = counters.tested
              return (
                <KeyValueRow
                  variant="grey"
                  style={{ flex: 1, minHeight: 70 }}
                  valueStyle={{ whiteSpace: 'unset', color: row.plannedForTesting ? 'green' : ' red' }}
                  label={`Data inizio: ${dateTime}`}
                  value={`${row.code} - ${pieces} pz.`}
                  borderColor={selected ? '#222222' : undefined}
                  image={selected ? <Icons.CheckFilled /> : undefined}
                />
              )
            }}
            customFooter={() => {
              return (
                <Legend>
                  <LegendRow>
                    <LegendIcon color="green" />
                    <LegendText>Lotto pianificato</LegendText>
                  </LegendRow>
                  <LegendRow>
                    <LegendIcon color="red" />
                    <LegendText>Lotto non pianificato</LegendText>
                  </LegendRow>
                </Legend>
              )
            }}
          />
        ),
      })
    })
  }

  startReader = async () => {
    try {
      this.setState({
        isReading: true,
      })
      if (!AppStore.emulation) {
        await QualityControlAgent.testConnection()
        const { options, selectedCausal } = this.state
        const mode = options.find((opt) => opt.active === true)?.value
        if (mode === 'SCARTO' && !selectedCausal) throw Error('Imposta una causale prima di procedere')
        const tag = await QualityControlAgent.readTag('fake', 'fake')
        if (tag.status === '4') return this.startReader() //non è stato letto niente, riparte la lettura
        if (tag.status === '99') return //Operazione interrotta che non mostra popup di error
        if (tag.status === '0' && tag.uid === '') throw Error('Errore di lettura') //Lettura andata a buon fine ma senza il tag
        if (tag.status !== '0') throw Error(tag.errorDesc) // Gestione di tutti gli altri tipi di errore
        this.setState({ tag })
        this.verifyReadedTag(tag)
      }
    } catch (error) {
      this.setState({ isReading: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  verifyReadedTag = async (tag: CQTag) => {
    if (this.state.lastReadedTag && this.state.lastReadedTag === tag.uid) return this.startReader()
    try {
      this.setState({
        isReading: false,
        isVerifing: true,
      })
      const { options, serie } = this.state
      const mode = options?.find((opt) => opt?.active === true)?.value
      let res
      if (this.tipoCQ === 'CENTO') {
        res = await EncodingsCQ.verifyCQWorker({
          productionOrderId: serie!.id,
          productId: serie!.productId,
          identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
          configurationId: this.operation.id,
          operationType: mode,
        })
      }
      else if (this.tipoCQ === 'CAMP_LOTTI') {
        res = await EncodingsCQ.verifyCQInspector({
          productionOrderId: serie!.id,
          productId: serie!.productId,
          identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
          configurationId: this.operation.id,
          operationType: mode,
          testingLotIds: serie?.data.currentLotData?.map(({ id }) => id),
        })
        if (!res.item) throw Error(__(T.error.not_collaudated_product))
      }
      if (res.errors.length > 0) {
        for (let i = 1; i <= res.errors.length; i++) {
          if (res.errors[i] && res.errors[i].errorCode) {
            //If there is more than one error, show them all in modals, than throw the first of them as effective error
            showToast({
              title: __(T.error.error),
              description: T.error[res.errors[i].errorCode.replaceAll('.', '_')]
                ? __(T.error[res.errors[i].errorCode.replaceAll('.', '_')])
                : res.errors[i].errorCode.replaceAll('.', '_'),
              status: 'error',
            })
          }
        }
        throw Error(
          T.error[res.errors[0].errorCode.replaceAll('.', '_')]
            ? __(T.error[res.errors[0].errorCode.replaceAll('.', '_')])
            : res.errors[0].errorCode.replaceAll('.', '_')
        )
      }
      if (
        res.warnings.length > 0 &&
        !(await askUserConfirmation(
          `${
            T.error[res.warnings[0].warningCode.replaceAll('.', '_')]
              ? __(T.error[res.warnings[0].warningCode.replaceAll('.', '_')])
              : res.warnings[0].warningCode.replaceAll('.', '_')
          }`,
          'Proseguire?'
        ))
      ) {
        return this.setState({ isVerifing: false })
      }
      this.setState({
        item: res.item,
        tag,
      })
      if (mode === 'SCARTO') {
        if (this.tipoCQ === 'CAMP_LOTTI') {
          return this.setState({ showScartoModal: true })
        }
        else if (this.tipoCQ === 'CENTO') {
          this.scartoItem(false)
        }
        else {
          throw Error(__(T.error.cq_type_not_found))
        }
      }
      this.setState({ isVerifing: false })
      if (mode === 'COLLAUDO') this.collaudoItem(tag.uid)
      if (mode === 'CANCELLAZIONE') {
        if (!res.item) throw Error('Impossibile eseguire cancellazione, nessun item associato')
        this.cancellaItem()
      }
    } catch (error) {
      this.setState({
        isReading: false,
        isVerifing: false,
      })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  collaudoItem = async ( epc?: string) => {
    const { tag, serie, counters, lastSearchedSerieCode, item } = this.state
    const additionalSerieData = serie?.data.additional
    try {
      DataDogLogger.addAction('processAssociate', { serie, tag })
      this.setState({ isWriting: true })
      if (counters!.itemStatusCounts.seriesSize - counters!.itemStatusCounts.inspectorTested <= 0)
        throw Error(__(T.error.serie_limit_associate_error))
      if (!AppStore.emulation) {
        const cliente = additionalSerieData?.customerOrder?.trim() || 'N00035'
        //if the string is less than 6 characters it will add 0 at the end of the string
        const clienteFinal = cliente.length < 6 ? cliente.padEnd(6, '0') : cliente
        const res = await QualityControlAgent.writeTagCQ(
          AppStore.loggedUser?.username as string,
          AppStore.defaultPostazione ?? '',
          tag?.uid as string,
          additionalSerieData?.workerSyncCode as string,
          serie?.code as string,
          (serie?.data.values.Stagione as string) ?? '-',
          (additionalSerieData?.productSku as string) ?? '',
          additionalSerieData?.productSize ?? '',
          clienteFinal,
          additionalSerieData?.nationCode ?? ''
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      if (this.tipoCQ === 'CAMP_LOTTI') {
        await EncodingsCQ.forceCollaudo({
          ...(item as any),
          status: 'CQ2',
          causal: undefined,
          upc: serie?.data.additional.productCode,
          productionOrderRow: item?.productionOrderRow,
          product: item?.product,
        })
      }
      else if (this.tipoCQ === 'CENTO') {
        await EncodingsCQ.inspectorCentoItemRead(serie!.data!.currentLotData![0].code, epc ?? '')
      }
      else {
        throw Error(__(T.error.cq_type_not_found))
      }
      this.setState({
        isWriting: false,
        isReading: false,
        lastReadedTag: tag?.uid,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.product_associated_correctly),
        status: 'success',
      })
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', this.isPelletteria)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processAssociate' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  scartoItem = async (reversible = true) => {
    const { tag, serie, selectedCausal, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDrop', { serie, tag, selectedCausal })
      this.setState({ showScartoModal: false, isVerifing: false, isReading: false, isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.writeTagSC(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? '',
          selectedCausal?.id as string
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      if (this.tipoCQ === 'CAMP_LOTTI') {
        const response = await EncodingsCQ.dropLotInspector({
          productionOrderId: serie!.id,
          productId: serie!.productId,
          identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
          zoneId: AppStore.defaultZone?.id,
          configurationId: this.operation.id,
          causalId: selectedCausal?.id,
          reversible,
          testingLotIds: serie?.data.currentLotData?.map(({ id }) => id),
        })
        if (!response.success) throw Error(__(T.error.product_drop_error))
      }
      else if (this.tipoCQ === 'CENTO') {
        await EncodingsCQ.inspectorCentoItemRead(serie!.data!.currentLotData![0].code, tag?.uid as string, selectedCausal?.id as string)
      }
      else {
        throw Error(__(T.error.cq_type_not_found))
      }
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto scartato correttamente'),
        status: 'success',
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDrop' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  cancellaItem = async () => {
    const { tag, serie, item, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDelete', { tag, serie, item })
      this.setState({ isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.cancelTag(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.tag_reset_error))
        }
      }
      await EncodingsCQ.deleteLotInspector({
        productionOrderId: item?.productionOrderRow?.order?.id ?? serie?.id,
        productId: item?.product?.id as string,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto cancellato correttamente'),
        status: 'success',
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDelete' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  stopReader = async () => {
    await QualityControlAgent.stopReader()
    await sleep(700)
    this.setState({
      isReading: false,
    })
  }

  //funzione che chiude il lotto
  closeLotto = async (lots?: CQLot[]) => {
    try {
      const { serie, toBeTested, inspectorTested, quantityToAdvance, causalQuantityMap, serieHasTag, lotQuantity, serieCode } = this.state
      const lotData = lots || serie!.data!.currentLotData!
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      if (this.tipoCQ === 'CAMP_LOTTI' && !serieHasTag) {
        if (this.isPelletteria) {
          if (quantityToAdvance.get(serieCode) ?? 0 === 0) {
            showToast({
              title: __(T.misc.warning),
              description: 'Nessun pezzo collaudato, lotto interrotto',
              status: 'warning',
            })
          }
          await EncodingsCQ.confirmInspectorLottiNoTag(serie!.data!.currentLotData![0].code, serie!.productId, causalQuantityMap.get(serieCode) ?? new Map<string, number>(), quantityToAdvance.get(serieCode) ?? 0)
        }
        else {
          await EncodingsCQ.confirmInspectorLottiNoTagCalzature(serie!.data!.currentLotData![0].code, causalQuantityMap, quantityToAdvance)
        }
      }
      else if (this.tipoCQ === 'CENTO' && !serieHasTag) {
        if (lotQuantity > 0) {
          if (this.isPelletteria) {
            if (quantityToAdvance.get(serieCode) === 0) {
              showToast({
                title: __(T.misc.warning),
                description: 'Nessun pezzo collaudato, lotto interrotto',
                status: 'warning',
              })
            }
            await EncodingsCQ.confirmInspectorCentoNoTag(serie!.data!.currentLotData![0].code, serie!.productId, causalQuantityMap.get(serieCode) ?? new Map<string, number>(), quantityToAdvance.get(serieCode) ?? 0)
          }
          else {
            await EncodingsCQ.confirmInspectorLottiNoTagCalzature(serie!.data!.currentLotData![0].code, causalQuantityMap, quantityToAdvance)
          }
        }
        else {
          throw Error('Nessun pezzo collaudato, impossibile avanzare il lotto')
        }
      }
      else {
        if (toBeTested! > inspectorTested! && !(await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.serie_found_discrepancy))))
          return this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
        const res = await EncodingsCQ.publishLotti({
          orderId: serie!.code,
          lotIds: lotData.map(({ id }) => id),
        })
        if (res.errors && res.errors.length > 0) {
          throw Error(T.error[res.errors[0].errorCode] ? __(T.error[res.errors[0].errorCode]) : res.errors[0].errorCode)
        }
      }
      this.clearStatus()
      return showToast({
        title: __(T.misc.success),
        description: `Lotto/i chiuso correttamente `,
        status: 'success',
      })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'closeSerie' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      return this.setState({ isActionInProgress: false })
    }
  }

  //funzione che chiude il lotto
  interruptLotto = async (lotIds: CQLot[] = [], code = '') => {
    try {
      const { serie, serieHasTag } = this.state
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      if (
        lotIds.length === 0 &&
        !(await askUserConfirmation(
          __(T.messages.serie_confirm_interrupt),
          __(T.messages.serie_interrupt_association_question)
        ))
      )
        return this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
      let res
      if (this.tipoCQ === 'CAMP_LOTTI' && !serieHasTag) {
        res = await EncodingsCQ.confirmInspectorLottiNoTag(serie!.data!.currentLotData![0].code, serie!.productId, new Map<string, number>(), 0)
      }
      else if (this.tipoCQ === 'CENTO' && !this.isWSM2 && !serieHasTag) {
        res = await EncodingsCQ.confirmInspectorCentoNoTag(serie!.data!.currentLotData![0].code, serie!.productId, new Map<string, number>(), 0)
      }
      else {
        res = await EncodingsCQ.interruptLotti({
          orderId: code !== '' ? code : serie!.id,
          lotIds: lotIds.length > 0 ? lotIds.map(({ id }) => id) : serie!.data!.currentLotData!.map(({ id }) => id),
        })
      }
      if (res && res.errors && res.errors.length > 0) {
        throw Error(T.error[res.errors[0].errorCode] ? __(T.error[res.errors[0].errorCode]) : res.errors[0].errorCode)
      }
      lotIds.length === 0 && this.clearStatus()
      this.setState({ isActionInProgress: false })
      return showToast({
        title: __(T.misc.success),
        description: `Lotto/i interrotto correttamente `,
        status: 'success',
      })
    } catch (error) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      return this.setState({ isActionInProgress: false })
    }
  }

  submitTestingSheet = async (testingSheet: Partial<TestingSheet>, isInModify: boolean) => {
    try {
      const { serie, qualitySheetData, testingSheetData } = this.state
      const { notes, attachmentFiles } = testingSheet

      DataDogLogger.addAction('submitTestingSheet', {
        serie: serie ?? 'serie is undefined',
        qualitySheetData,
        testingSheetData,
        notes,
      })

      if (!isInModify) {
        const files = {}
        attachmentFiles.map((file) =>
          Object.assign(files, { [file.name]: { content: file.base64, description: file.name } })
        )
        const newTestingSheet = {
          qualitySheetId: qualitySheetData?.id,
          orderId: serie!.id,
          notes: notes,
          attachmentFiles: files,
        }

        await EncodingsCQ.createTestingSheet(newTestingSheet)
        const res = (await EncodingsCQ.getTestingSheet(serie!.id))[0]
        this.setState({ testingSheetData: res })
      } else {
        if (!testingSheetData) throw Error('Testing sheet not set')

        const files = {}
        attachmentFiles
          .filter((file) => !!file.preview)
          .map((file) => Object.assign(files, { [file.name]: { content: file.base64, description: file.name } }))
        const newTestingSheet = {
          id: testingSheetData.id,
          inspectionNote: testingSheet.notes as string,
          attachments: testingSheetData.attachments,
          qualitySheet: testingSheetData.qualitySheet,
          code: testingSheetData.code,
        }
        await EncodingsCQ.addFilesQualitySheet(newTestingSheet.code, files)
        const res = await EncodingsCQ.updateTestingSheet(newTestingSheet)
        this.setState({ testingSheetData: res })
      }
      this.toggleSeriesNotesModal()
    } catch (error) {
      DataDogLogger.addError(error, { type: 'submitTestingSheet' })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      this.toggleSeriesNotesModal()
    }
  }

  toggleSeriesNotesModal = () => {
    const { showSeriesNotesModal, serie } = this.state
    DataDogLogger.addAction('toggleSeriesNotesModal', {
      serie: serie ?? 'serie is undefined',
      show: !showSeriesNotesModal,
    })
    this.setState({ showSeriesNotesModal: !this.state.showSeriesNotesModal })
  }

  //Mostra modale per info lotti
  showLotsModal = async () => {
    const { lots, serie, counters } = this.state
    const lotCounts = counters?.itemStatusCounts.lotCounts
    const serieCode = serie?.code
    const modalId = 'cq-lots'
    let options: any[] = []
    for (let i = 0; i < lots!.length; i++) {
      const currentLotCount = { tested: lotCounts?.find((itmInner) => itmInner.lotId === lots![i].id)?.tested }
      options.push({
        ...lots![i],
        ...currentLotCount,
      })
    }
    options = options.sort((a, b) => (a.code > b.code ? 1 : -1))
    return new Promise((resolve) => {
      openModal({
        id: modalId,
        body: (
          <SelectLotsModal
            modalId={modalId}
            title="LOTTI CONFERMATI"
            visible
            options={options ?? []}
            onSelect={(items) => {
              resolve(items)
              closeModal(modalId)
            }}
            lotCode="code"
            date="creationDate"
            serie={serieCode ?? ''}
            onClose={() => {
              closeModal(modalId)
            }}
          />
        ),
      })
    })
  }

  renderHeader = () => {
    const { options, isReading, isVerifing, isWriting, qualitySheetData, serie, isSerieSet } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    return (
      <>
        {serie && qualitySheetData && <CustomBtn onClick={this.toggleSeriesNotesModal}>Note Serie</CustomBtn>}
        {qualitySheetData && (
          <Button variant="secondary" style={{ minHeight: 55 }} onClick={this.toggleQualitySheetDetail}>
            Scheda qualità
          </Button>
        )}
        <Spacer />
        {isSerieSet && <Tab options={options} onOptionSelected={this.changeOptions} disabled={showAntennaReadingIcon} />}
      </>
    )
  }

  renderBottomBar = () => {
    const { isReading, isVerifing, isWriting, hideStartReaderButton } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    return (
      <>
        {showAntennaReadingIcon && (
          <Box row>
            <Box flex>
              <AntennaBottomControl />
            </Box>
            <Spacer />
            <Button title="Stop lettore" onClick={this.stopReader} disabled={isWriting || isVerifing} />
          </Box>
        )}
        {!showAntennaReadingIcon && !hideStartReaderButton && (
          <Button title={__(T.placeholder.start_reader)} onClick={this.startReader} />
        )}
      </>
    )
  }

  renderScartoModal = () => {
    return (
      <Box>
        <TextBox text="Seleziona un tipo di scarto" type="error" />
        <Box row mt={15} center>
          <Button
            variant="default"
            title={__(T.misc.cancel)}
            onClick={() => {
              return this.setState({ showScartoModal: false })
            }}
          />
          <Spacer />
          <Button title="Reversibile" onClick={this.scartoItem} />
          <Spacer />
          <Button title="Irreversibile" onClick={() => this.scartoItem(false)} />
        </Box>
      </Box>
    )
  }

  renderInfoSerie = () => {
    const {
      serie,
    } = this.state
    if (!serie) return

    return (
      <>
        <InfoCard item={serie!.data.values} title="Serie" subtitle={serie!.code} />
        <Spacer />
        <CounterContainer onClick={() => {this.showLotsModal()}}>
          <Card title="Info lotti" nomargin style={{ minWidth: 125, padding: 15 }}>
            <Counter>{serie!.data!.currentLotData![0].code}</Counter>
            <Box style={{ flexDirection: 'row', justifyContent: 'right', marginTop: -20, zIndex: 1 }}>
              <Icons.Info
                style={{ alignSelf: 'center' }}
                width={20}
                height={20}
              />
            </Box>
          </Card>
        </CounterContainer>
      </>
    )
  }
  renderSideBar = () => {
    const { errorSerie, isReading, isWriting, isVerifing, warningText, options, isVAS, isSerieSet } = this.state
    const isDisabled = isReading || isWriting || isVerifing
    const selectedOption = options.find((opt) => opt.active === true)?.value
    return (
      <>
        <Page.Sidebar style={{ overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Input
            inputRef={this.inputRef}
            autoFocus={(this.inputRef?.current?.value === '' || !isSerieSet) && selectedOption !== 'SCARTO'}
            onChange={() => this.forceUpdate()}
            barcode
            error={errorSerie}
            placeholder="Barcode serie"
            onEnter={this.searchSerie}
            disabled={isDisabled}
            focusOptions={{ preventScroll: true }}
          />
          <Spacer />
          {warningText && (
            <>
              <WarningText text={warningText} type="warning" p={2} />
              <Spacer />
            </>
          )}
          {isVAS && (
            <>
              <WarningText text="ATTENZIONE SERIE VAS" type="warning" p={2} />
              <Spacer />
            </>
          )}
          {this.renderInfoSerie()}
        </Page.Sidebar>
      </>
    )
  }

  renderIsReadingBox = () => {
    return (
      <Box flex center>
        <Placeholder style={{ width: 370 }}>{__(T.messages.near_product_to_reader)}</Placeholder>
      </Box>
    )
  }

  renderIsVerifingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Verifica in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderIsWritingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Scrittura in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderScartoSelect = () => {
    const { selectedCausal, causal } = this.state
    return (
      <Box flex style={{ width: '100%' }}>
        <Select
          onSelect={(causalSel) => {
            this.setState({ selectedCausal: causalSel })
          }}
          placeholder={__(T.placeholder.drop_causal)}
          options={causal}
          config={{ value: 'id', label: 'fastId', secondaryLabel: 'description' }}
          isClearable={false}
          defaultValue={selectedCausal}
          multiLabel
        />
      </Box>
    )
  }

  renderQualitySheetModal = () => {
    const { showQualitySheetModal, serie, qualitySheetData } = this.state
    return (
      <>
        {showQualitySheetModal && (
          <QualitySheetModal
            serie={serie}
            qualitySheet={qualitySheetData}
            onClose={this.toggleQualitySheetDetail}
            visible={this.state.showQualitySheetModal}
          />
        )}
      </>
    )
  }

  renderCausale () {
    const { causal, causalsCount, serieCode } = this.state
    if (causal.length === 0 || causalsCount === 0) return null
    return (
      <QualityControlCausal
        id={serieCode}
        onDecrement={() => this.decrementQuantity(serieCode)}
        onIncrement={() => this.incrementQuantity(serieCode)}
        onQuantityChange={(causalId, quantity, oldValue) => this.onQuantityChange(causalId, quantity, oldValue)}
        checkMaxQuantity={() => this.checkMaxQuantity()}
        checkSelectedCausals={() => this.checkSelectedCausals()}
      />
    )
  }

  decrementQuantity = (rowCode: string) => {
    const { quantityToAdvance } = this.state
    if (quantityToAdvance.get(rowCode) === 0) return
    quantityToAdvance.set(rowCode, (quantityToAdvance.get(rowCode) ?? 0) - 1)
    this.setState({quantityToAdvance})
  }

  incrementQuantity = (rowCode: string) => {
    const { quantityToAdvance  } = this.state
    quantityToAdvance.set(rowCode,( quantityToAdvance.get(rowCode) ?? 0) + 1)
    this.setState({quantityToAdvance})
  }

  onQuantityChange = (causal: string, quantity: number, oldValue: string | null, rowCode?: string) => {
    const { causalQuantityMap, serieCode } = this.state
    if (this.isPelletteria) {
      if (oldValue) {
        causalQuantityMap.get(serieCode)?.delete(oldValue)
      }
      causalQuantityMap.get(serieCode)?.set(causal, quantity)
    }
    else {
      if (rowCode) {
        causalQuantityMap.get(rowCode)?.delete(oldValue ?? '')
        if (!causalQuantityMap.has(rowCode)) {
          causalQuantityMap.set(rowCode, new Map<string, number>())
        }
        causalQuantityMap.get(rowCode)?.set(causal, quantity)
      }
    }
    this.setState({causalQuantityMap})
  }

  checkMaxQuantity = (rowCode?: string) => {
    const { causalQuantityMap, testedPieces, isSerieSet, quantityToAdvance, serieCode, serie } = this.state
    let total = 0
    if (!isSerieSet) return true
    if (this.isPelletteria) {
      for (const key in causalQuantityMap.get(serieCode)) {
        total += causalQuantityMap.get(serieCode)?.get(key) ?? 0
      }
    }
    else {
      if (!rowCode) return true
      for (const key in causalQuantityMap.get(rowCode)) {
        total += causalQuantityMap.get(rowCode)?.get(key) ?? 0
      }
      return total >= (quantityToAdvance.get(rowCode) ?? 0)
    }
    if (this.tipoCQ === 'CAMP_LOTTI') {
      return total >= testedPieces
    }
    else if (this.tipoCQ === 'CENTO') {
      let remaining = serie?.data.values['Pezzi totali serie'] ?? 0
      for (const key in quantityToAdvance) {
        remaining -= quantityToAdvance.get(key) ?? 0
      }
      return remaining === 0
    }
    return true
  }

  checkSelectedCausals = (rowCode?: string) => {
    const { causal, causalQuantityMap, serieCode } = this.state
    let nonSelectedCausals: Causal[] = []
    if (this.isPelletteria) {
      rowCode = serieCode ?? ''
    }
    causal.forEach((c) => {
      if (!causalQuantityMap.get(rowCode ?? '')?.has(c.id) && causalQuantityMap.get(rowCode ?? '')?.get(c.id) !== 0) {
        nonSelectedCausals.push(c)
      }
    })
    return nonSelectedCausals
  }

  renderCausalsCalz = () => {
    const { causalQuantityMap, quantityToAdvance } = this.state;
    if (!causalQuantityMap) return null
    return (
      <>
        {Array.from(causalQuantityMap.entries()).map(([key, valueMap]) => (
          <React.Fragment key={key}>
            <Box row>
              <Counter>{key}</Counter>
              <Spacer />
              <Counter style={{ marginLeft: 'auto', textAlign: 'right' }}>{__(T.misc.quantity_to_advance)}: {quantityToAdvance.get(key) ?? 0}</Counter>
            </Box>
            {Array.from({ length: valueMap.size }).map(() => (
              <>
                <QualityControlCausal
                  id={key}
                  onQuantityChange={(causal, quantity, oldValue) => this.onQuantityChange(causal, quantity, oldValue, key)}
                  checkSelectedCausals={() => this.checkSelectedCausals(key)}
                  checkMaxQuantity={() => this.checkMaxQuantity(key)}
                  onIncrement={() => this.incrementQuantity(key)}
                  onDecrement={() => this.decrementQuantity(key)}
                />
              </>
            ))}
            <Spacer />
          </React.Fragment>
        ))}
      </>
    );
  }

  renderInsertQuantity = () => {
    const { quantityToAdvance, options, serieCode } = this.state
    const mode = options.find((opt) => opt.active === true)?.value
    if (this.isPelletteria) {
      if (this.tipoCQ === 'CAMP_LOTTI') {
        return (
          <CounterContainer row>
            <Card title={__(T.misc.quantity_to_advance)} nomargin style={{ flexGrow: 1, padding: 5, paddingLeft: 10 }}>
              <Counter>{quantityToAdvance}</Counter>
            </Card>
          </CounterContainer>
        )
      }
      else if (this.tipoCQ === 'CENTO') {
        return (
          <QualityControlQuantity
            quantityToAdvance={quantityToAdvance.get(serieCode) ?? 0}
            checkMaxQuantity={() => this.checkMaxQuantityToAdvance(serieCode)}
            onDecrementQuantity={() => this.onDecrementQuantity(serieCode)}
            onIncrementQuantity={() => this.onIncrementQuantity(serieCode)}
            enableIcons={mode !== 'SCARTO'} />
        )
      }
    }
    else if (mode === 'COLLAUDO') {
      return (
        <>
          {Array.from(quantityToAdvance.entries()).map(([key, value]) => (
            <React.Fragment key={key}>
              <Counter>{key}</Counter>
              <QualityControlQuantity
                quantityToAdvance={value}
                checkMaxQuantity={() => this.checkMaxQuantityToAdvance(key)}
                onDecrementQuantity={() => this.onDecrementQuantity(key)}
                onIncrementQuantity={() => this.onIncrementQuantity(key)}
                enableIcons
              />
              <Spacer />
            </React.Fragment>
          ))}
        </>
      )
    }
  }

  checkMaxQuantityToAdvance = (rowCode: string) => {
    const { quantityToAdvance, originalQuantity } = this.state
    return (quantityToAdvance.get(rowCode) ?? 0) >= (originalQuantity.get(rowCode) ?? 0)
  }

  onDecrementQuantity = (rowCode: string) => {
    const { quantityToAdvance, lotQuantity } = this.state
    quantityToAdvance.set(rowCode, (quantityToAdvance.get(rowCode) ?? 0) - 1)
    this.setState({ quantityToAdvance, lotQuantity: lotQuantity - 1 })
  }

  onIncrementQuantity = (rowCode: string) => {
    const { quantityToAdvance, lotQuantity } = this.state
    quantityToAdvance.set(rowCode, (quantityToAdvance.get(rowCode) ?? 0) + 1)
    this.setState({ quantityToAdvance, lotQuantity: lotQuantity + 1 })
  }

  emulateTag = (tags: string[]) => {
    const tagRead = {
      errorDesc: '',
      status: '0',
      uid: tags[0],
      uuid: '045442D2B16C80045442D2B16C80',
    }
    this.verifyReadedTag(tagRead)
  }

  render() {
    const {
      isReading,
      isVerifing,
      isWriting,
      isSerieSet,
      options,
      showScartoModal,
      showSeriesNotesModal,
      testingSheetData,
      causalsCount,
      causalQuantityMap,
      hasUnexpectedTag,
      isActionInProgress,
      inspectorTested,
      toBeTested,
      discarded,
      serieHasTag,
      testedPieces,
      lotQuantity,
      counters,
      serie,
      serieCode,
    } = this.state
    const disableButton = isWriting || isVerifing || (hasUnexpectedTag && serieHasTag)
    const mode = options.find((opt) => opt.active === true)?.value
    const collaudati = QualityProvider.getCollaudati(inspectorTested, counters)
    const scartati = QualityProvider.getScartati(discarded, counters)
    const daTestare = QualityProvider.getDaTestare(collaudati, scartati, toBeTested, serie)

    return (
      <Page
        title="Quality Control"
        enableEmulation
        headerRight={this.renderHeader()}
        emulationFunction={this.emulateTag}
      >
        {this.renderSideBar()}
        <Page.Content>
          {isSerieSet && (
            <>
              <CounterContainer row>
                <CounterContainer flex>
                  <CounterContainer row flex>
                    <Card title="Pezzi totali lotto" nomargin style={{ padding: 5, paddingLeft: 10, flex: 1 }}>
                      {this.tipoCQ === 'CENTO' && <Counter>{lotQuantity}</Counter>}
                      {this.tipoCQ === 'CAMP_LOTTI' && <Counter>{testedPieces === 0 ? '-' : testedPieces}</Counter>}
                    </Card>
                    <CustomSpacer />
                    <Card title={__(T.placeholder.tested)} nomargin style={{ padding: 5, paddingLeft: 10, flex: 1 }}>
                      <Counter>{collaudati === 0 ? '-' : collaudati}</Counter>
                    </Card>
                  </CounterContainer>
                  <CustomSpacer />
                  <CounterContainer row>
                    <Card title="Scarti NR" nomargin style={{ padding: 5, paddingLeft: 10, flex: 1 }}>
                      <Counter>{scartati === 0 ? '-' : scartati}</Counter>
                    </Card>
                    <CustomSpacer />
                    <Card title="Da testare" nomargin style={{ padding: 5, paddingLeft: 10, flex: 1 }}>
                      <Counter>{daTestare === 0 ? '-' : daTestare}</Counter>
                    </Card>
                  </CounterContainer>
                </CounterContainer>
                <CustomSpacer />
                <CounterContainer flex>
                  <Box flex style={{ justifyContent: 'flex-end' }}>
                    <Button
                      title="Conferma lotti"
                      disabled={disableButton}
                      loading={isActionInProgress}
                      variant="primary"
                      onClick={this.closeLotto}
                    />
                  </Box>
                  <CustomSpacer />
                  <Button
                    title="Interrompi lotti"
                    disabled={disableButton}
                    loading={isActionInProgress}
                    variant="error"
                    onClick={this.interruptLotto}
                  />
                </CounterContainer>
              </CounterContainer>
              <CustomSpacer />
              {!serieHasTag && (
                <>
                  {this.renderInsertQuantity()}
                  <Spacer />
                </>
              )}
              {mode === 'SCARTO' && !serieHasTag && (
                <>
                  {this.isPelletteria && (
                    <>
                      <Button
                        title = "Aggiungi causale di scarto"
                        onClick = {() => {
                          let totalCausals = 0
                          if (!isSerieSet) return
                          for (const key in causalQuantityMap.get(serieCode)) {
                            totalCausals++
                          }
                          if (causalsCount !== totalCausals) {
                            return
                          }
                          this.setState({causalsCount: causalsCount + 1})
                        }}
                      />
                      <Spacer />
                      <Box flex style={{overflowY:'auto'}}>
                        {Array.from({ length: causalsCount }).map((_, index) => (
                          <>
                            {this.renderCausale()}
                          </>
                        ))}
                        <Spacer />
                      </Box>
                    </>
                  )}
                  {!this.isPelletteria && (
                    <>
                      <Input
                        barcode
                        placeholder={"Scansiona un codice taglia/calzatura da scartare"}
                        onEnter={(row) => this.addCausaleCalz(row)}
                      />
                      <Spacer/>
                      {this.renderCausalsCalz()}
                    </>
                  )}
                </>
              )}
              {serieHasTag && !showScartoModal && (
                <>
                  {mode === 'SCARTO' && this.renderScartoSelect()}
                  <Box flex hcenter vcenter>
                    {isReading && this.renderIsReadingBox()}
                    {isVerifing && this.renderIsVerifingBox()}
                    {isWriting && this.renderIsWritingBox()}
                  </Box>
                  <Spacer />
                  {this.renderBottomBar()}
                </>
              )}
            </>
          )}
          {showScartoModal && this.renderScartoModal()}
        </Page.Content>
        {this.renderQualitySheetModal()}
        {showSeriesNotesModal && (
          <SeriesNotesModal
            onSubmitCallback={(testingSheet, updateSheet) => this.submitTestingSheet(testingSheet, updateSheet)}
            testingSheet={testingSheetData}
            onClose={this.toggleSeriesNotesModal}
            visible={this.state.showSeriesNotesModal}
          />
        )}
      </Page>
    )
  }
}

const TagReadBox = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 20px;
  border-radius: 20px;
`

const TagReadPlaceholder = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  cursor: default;
  ${({ color }) => `color: ${color}`};
`

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
`

const CounterContainer = styled(Box)`
  justify-content: space-between;
`
const WarningText = styled(TextBox)`
  font-size: 15px;
`
const Legend = styled(Box)`
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`
const LegendRow = styled(Box)`
  display: flex;
  flex-direction: row;
`
const LegendIcon = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  ${({ color }) => `background-color: ${color}`};
`
const LegendText = styled(Box)`
  font-size: 15px;
`
const CustomBtn = styled(Button)`
  background: none;
  color: #3265c7;
  border-color: none;
  box-shadow: none;
  text-decoration: underline;
`
