import React, { Component } from 'react'
import { AntennaBottomControl, Button, CustomItemList, Page, Spacer, TagCounter } from 'components'
import { CQTag, CustomTmrItem } from 'api/types'
import QualityControlAgent from 'pages/QualityControl/QualityControlAgent'
import EncodingsCQ from 'api/EncodingsCQ'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

interface State {
  items: CustomTmrItem[]
  tag?: CQTag
  isReading: boolean
}

export default class TagInfoQualityControl extends Component<{}, State> {
  state: State = {
    items: [],
    isReading: false,
  }

  clear = () => this.setState({ items: [] })

  startReader = async () => {
    this.setState({
      isReading: true,
    })
    QualityControlAgent.readTag('fake', 'fake')
      .then((tag) => this.onTagReadCallback(tag as CQTag))
      .catch((error) => {
        console.log(error)
        this.setState({ isReading: false }) //show start reader because readTag is failed and the reader is not reading anymore
      })
  }

  onTagReadCallback = async (tag: CQTag) => {
    try {
      const { items } = this.state
      this.setState({ isReading: false })

      if (tag.status === '1') throw Error('Impossibile connettersi al lettore')
      if (tag.uid === '' || tag.status !== '0') throw Error('Prodotto letto non valido')

      let item = (await EncodingsCQ.fetchItemByIdentifier(tag.uid))[0]

      if (!item) item = { id: tag.uuid, epc: tag.uid }
      if (items.filter((itm) => itm.id === item.id).length > 0) return
      items.unshift(item)
      this.setState({ items })
    } catch (error) {
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  emulateTag = (tags: string[]) => {
    const tagRead = {
      errorDesc: '',
      status: '0',
      uid: tags[0],
      uuid: tags[0],
    }
    this.onTagReadCallback(tagRead)
  }

  render() {
    const { items, isReading } = this.state

    return (
      <Page title="TagInfo" enableEmulation emulationFunction={this.emulateTag}>
        <Page.Sidebar>
          <TagCounter detected={items.length} />
          {!isReading && <Button title="Avvia lettore" onClick={this.startReader} />}
          <Spacer />
          {items.length > 0 && <Button variant={'secondary'} title="Resetta" onClick={this.clear} />}
        </Page.Sidebar>

        <Page.Content>
          <CustomItemList items={items} emptyMessage={isReading ? 'Avvicina il prodotto' : undefined} />
          {isReading && <AntennaBottomControl />}
        </Page.Content>
      </Page>
    )
  }
}
